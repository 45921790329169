.tableRow {
  width: 100%;
  background-color: var(--white);
  border-bottom: 4px solid var(--grey-CPH);
  padding: 20px 24px;
  display: flex;
  align-items: center;
}

.tableRow:first-child {
  border-radius: 10px 10px 0 0;
}

.tableRow:last-child {
  border-bottom: none;
  border-radius: 0 0 10px 10px;
}

.tableRow:first-child:last-child {
  border-radius: 10px;
}

.eeuTableRow {
  height: 134px;
  font-size: 32px;
}

.aeuTeuTableRow {
  height: 134px;
  font-size: 32px;
}

.aeuTeuTableRowTimestamp {
  font-size: 32px;
}

.tableRowBlock {
  height: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}

.arrivalTranserInfoWrapper {
  display: flex;
  height: 100%;
}

.arrivalWrapper {
  background-color: var(--purple);
  border-radius: 0.5rem 0 0 0.5rem;
  color: white;
  padding: 1rem;
}

.transferWrapper {
  background-color: var(--yellow-CPH);
  border-radius: 0 0.5rem 0.5rem 0;
}

.arrivalTransferInfo {
  display: flex;
  font-size: 1rem;
  width: 11.5rem;
}

.arrivalTransferInfoIcon {
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrivalTransferInfoTextWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  font-family: var(--secondary-font);
}

.arrivalTransferInfoText {
  font-size: 1.3rem;
  line-height: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 5rem;
}

.handlerRef {
  color: var(--blue-CPH-50);
}

.beltInformationWrapper {
  display: flex;
  justify-content: flex-end;
}

.beltInformationBackground {
  display: flex;
  height: 2.8rem;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background-color: var(--pale-grey-50);
  border-radius: 50px;
}

.beltInformationBackgroundCheckedIn {
  background-color: var(--green-CPH-10);
  color: var(--blue-CPH-50) !important;
}

.beltInformationBackgroundCheckedIn p {
  color: var(--green-CPH-50);
}

.beltInformationTextWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25rem;
}

.beltInformationCheckedInTextBlock {
  width: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.beltInformationIdividualBlock {
  display: flex;
}

.beltInformationIdividualBlock p {
  width: 3.9rem;
}

.beltInformationIdividualBlock__text {
  width: 5rem !important;
}

.beltInformationIdividualBlock p:last-child {
  margin-left: 0.5rem;
}

.tableButton {
  border: none;
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableButtonBlue {
  background-color: var(--dark-blue-CPH);
  color: white;
}

.tableButtonWhite {
  background-color: var(--white);
  color: rgb(51, 184, 23);
}

.errorMessageWrapper {
  background-color: var(--red-error-bg);
  border-radius: 3rem;
  padding: 0.5rem 1.5rem;
  margin-right: 10px;
}

.errorMessage {
  color: var(--red) !important;
  font-size: 01rem;
}

@keyframes fullScaleOnReturn {
  0% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.2);
  }
}

.animateScalingOnReturnBehaviour {
  animation: fullScaleOnReturn 1s;
}

@keyframes XScaleOnReturn {
  0% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.05, 1.2);
  }
}

.animateXScalingOnReturnBehaviour {
  animation: XScaleOnReturn 1s;
}

@keyframes moveBagAcrossSpace {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(450%);
  }
}

.animatemoveBagAcrossSpace {
  animation: moveBagAcrossSpace 2s cubic-bezier(0, 0, 0, 0) infinite;
}

.bagAnimationContainer {
  min-width: 225px;
  margin-left: 20px;
  height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 4px;
  overflow: hidden;
}

.animationBottomBar {
  width: 100%;
  border: solid 3px var(--dark-blue-CPH);
  height: 13px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--blue-CPH-10) 0px 0px 0px 4px;
}
