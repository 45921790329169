.header {
	background-color: var(--dark-blue-CPH);
	width: 100%;
	height: 114px;
	position: fixed;
	top: 0;
	margin: 0;
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--white);
	box-shadow: var(--light-periwinkle) 0px 3px 8px;
}

.clock {
	font-size: 32px;
	font-family: var(--secondary-font);
}

.headerPageTitle {
	background-color: var(--light-periwinkle);
	height: 3.5rem;
	width: 3.5rem;
	margin-top: -1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
}

.headerLabel {
	border-radius: 3rem;
	display: flex;
	height: 64px;
	padding: 0 50px;
	gap: 15px;
	width: min-content;
	align-items: center;
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	font-size: 32px;
}

.textLabel {
	display: flex;
	gap: 0.7rem;
	justify-content: center;
	align-items: center;
	width: 10rem;
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}
