/* titles */
.modal-title {
  font-size: var(--modal-title);
  font-weight: bolder;
}

.table-title {
  font-size: var(--table-title);
  color: var(--dark-blue-CPH);
  font-weight: 500;
  font-family: var(--secondary-font);
}

.table-font {
  font-size: 1.3rem;
  color: var(--dark-blue-CPH);
  font-weight: normal;
}

.label {
  font-size: 1.1rem;
  background-color: var(--white);
  border-radius: 1rem;
  width: 8rem;
  height: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

h3 {
  font-size: 32px;
  font-weight: 500;
}

.completed-text {
  font-family: "Roboto-mono";
  font-weight: 700;
  color: var(--green-CPH);
  margin-right: 20px;
  padding: 24px;
  min-width: 225px;
}
