:root {
    /* Design choice */
    --border-radius: 0.8rem;
    /* color */
    /* white and greys */
    --white: #ffffff;
    --grey-CPH: #dae2e7;
    --grey-CPH-opacity: rgb(218, 226, 231, 0.5);
    --light-grey-CPH: #f0f3f5;
    --pale-grey-50: rgba(242, 247, 254, 0.5);
    --maintenance-gray: #efefef;
    --loading-grey:#707789;
    --loading-disable-grey:#EFF1F6;
    /* blues */
    --blue-CPH: rgb(50, 76, 126);
    --blue-CPH-10: rgb(230, 233, 239);
    --light-blue-10: rgba(47, 128, 237, 0.1);
    --light-blue: rgba(47, 128, 237, 1);
    --blue-CPH-20: rgb(51, 78, 128, 0.2);
    --blue-CPH-50: rgb(51, 78, 128, 0.5);
    --dark-blue-CPH: rgb(2, 35, 91);
    --dark-blue-CPH-50: rgb(2, 35, 91, 0.5);
    --powder-blue: #beceec;
    /* green and yellow */
    --yellow-CPH: #fdca2f;
    --yellow-CPH-10: #FFFAEB;
    --green-CPH: rgb(51, 185, 29);
    --green-CPH-10: #EDF7EA;
    /* red */
    --red-10: rgba(255, 81, 83, 0.1);
    --red-30: rgba(255, 81, 83, 0.3);
    --red-50: rgba(255, 81, 83, 0.5);
    --red-75: rgba(255, 81, 83, 0.75);
    --red-error-bg: #FFEEEF;
    --red: #ff5153;
    /* other */
    --light-periwinkle: #dde3ef;
    --purple: rgba(133, 96, 255, 1);
    /* font */
    --primary-font: 'Roboto';
    --secondary-font: 'Roboto-mono';
    --modal-title: 4rem;
    --table-title: 52px;
    --text-md: 32px;

}