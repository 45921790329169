.noBagsButtonLoadingState {
  border: 4px solid var(--blue-CPH-10);
  color: var(--white);
  background-color: var(--loading-disable-grey);
}

/* Increases specificity to override `.standardButton:disabled` styles */
.noBagsButtonLoadingState.noBagsButtonLoadingState:disabled {
  background-color: var(--loading-disable-grey) !important;
}

.loadingButton {
  background-color: var(--white);
  color: var(--loading-grey);
  border: 1px solid var(--white);
}

/* Increases specificity to override `.standardButton:disabled` styles */
.loadingButton.loadingButton:disabled {
  background-color: var(--white) !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  animation: spin 1s linear infinite;
}
